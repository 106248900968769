<template>
  <div style="max-width: 1800px; margin: auto">
    <v-card class="main-card">
      <h2 v-if="locale === 'en'">Shopping cart </h2>
      <h2 v-else-if="locale === 'de'">Warenkorb</h2>
      <h2 v-else>Košarica</h2>
      <hr style="margin-top: 10px; margin-bottom: 20px">
      <v-row>
        <v-col cols="12" md="7" sm="12" v-if="localCart.length !== 0">
          <div v-for="item in localCart" :key="item.id" class="item-div">
            <v-row>
              <v-col cols="12" md="3" sm="3">
                <div class="image-div">
                  <img :src="itemImagesUrl + item.item.defaultImage" style="width: 100%; object-fit: contain; margin-right: 2%;aspect-ratio : 1 / 1;">
                </div>
              </v-col>
              <v-col cols="12" md="9" sm="9">
                <div class="text-div">
                  <v-icon style="position: relative; float: right; right: 10px;" @click="removeItemFromLocalCart(item)">mdi-close</v-icon>
                  <h3 v-if="locale === 'en'">{{item.item.nameEN}}</h3>
                  <h3 v-else-if="locale === 'de'">{{item.item.nameDE}}</h3>
                  <h3 v-else>{{item.item.nameSI}}</h3>
                  <i style="font-size: 15px;">(</i>
                  <i v-if="item.item.discount > 0" style="font-size: 12px; text-decoration: line-through red">{{ item.option.price.toFixed(2) }} {{ getUnit(item.item.type) }}</i>
                  <i v-if="item.item.discount > 0" style="font-size: 15px; margin-left: 4px">{{ applyDiscountWithoutFormatting(item.option.price, item.item.discount).toFixed(2) }} {{ getUnit(item.item.type) }})</i>
                  <i v-if="item.item.discount === 0" style="font-size: 15px; margin-left: 4px">{{ item.option.price.toFixed(2) }} {{ getUnit(item.item.type) }})</i>
                  <br>

                  <div class="text-right">
                    <h3 style="margin-bottom: 10px">
                      <v-chip :color="'#c7c7c7'" class="" v-if="item.item.discount === 0"><b style="font-size: 19px">{{ formatPrice(item.option.price, item.item.type, item.quantity, item.width, item.height) }} €</b></v-chip>
                      <v-chip :color="'#c7c7c7'" class=""  v-else>
                        <i style="font-size: 14px; text-decoration: line-through red">
                          {{ formatPrice(item.option.price, item.item.type, item.quantity, item.width, item.height) }} €
                        </i>
                        <b style="font-size: 19px; margin-left: 5px">
                          {{ applyDiscount(item.option.price, item.item.discount, item.item.type, item.quantity, item.width, item.height) }} €
                        </b>
                      </v-chip>
                    </h3>
                  </div>

                  <p style="margin-bottom: 0">{{ getDetailsText(item) }}</p>


                  <p v-if="locale === 'en'" style="margin-bottom: 0">NET: {{ getNET(item.option.price, item.item.discount, item.item.type, item.quantity, item.width, item.height) }} €</p>
                  <p v-else-if="locale === 'de'" style="margin-bottom: 0">Netto: {{ getNET(item.option.price, item.item.discount, item.item.type, item.quantity, item.width, item.height) }} €</p>
                  <p v-else style="margin-bottom: 0">NETO: {{ getNET(item.option.price, item.item.discount, item.item.type, item.quantity, item.width, item.height) }} €</p>

                  <p v-if="locale === 'en'">VAT: {{ getVAT(item.option.price, item.item.discount, item.item.type, item.quantity, item.width, item.height) }} €</p>
                  <p v-else-if="locale === 'de'">MwSt: {{ getVAT(item.option.price, item.item.discount, item.item.type, item.quantity, item.width, item.height) }} €</p>
                  <p v-else>DDV: {{ getVAT(item.option.price, item.item.discount, item.item.type, item.quantity, item.width, item.height) }} €</p>
                </div>
              </v-col>
            </v-row>
          </div>

          <hr style="margin-top: 30px; margin-bottom: 5px">
          <div class="text-right">
            <i style="font-size: 15px">{{ getTranslationFor("shipping") }}: {{ properties.shipping.toFixed(2) }}€</i><br><br>

            <h3 v-if="locale === 'en'">Total: {{ calculateCartTotal().total }} €</h3>
            <h3 v-else-if="locale === 'de'">Total: {{ calculateCartTotal().total }} €</h3>
            <h3 v-else>Skupaj: {{ calculateCartTotal().total }} €</h3>

            <p v-if="locale === 'en'" style="margin-bottom: 0">NET: {{calculateCartTotal().net}} €</p>
            <p v-else-if="locale === 'de'" style="margin-bottom: 0">NETTO: {{calculateCartTotal().net}} €</p>
            <p v-else style="margin-bottom: 0">NETO: {{calculateCartTotal().net}} €</p>

            <p v-if="locale === 'en'">VAT: {{calculateCartTotal().vat}} €</p>
            <p v-else-if="locale === 'de'">MwSt: {{calculateCartTotal().vat}} €</p>
            <p v-else>DDV: {{calculateCartTotal().vat}} €</p>
          </div>



        </v-col>
        <v-col cols="12" md="7" sm="12" v-else class="text-center">
          <h3 style="margin-top: 100px; margin-bottom: 100px">Prazna košarica</h3>
        </v-col>
        <v-col cols="12" md="5" sm="12">
          <v-card style="padding: 30px">
            <h3 v-if="locale === 'en'">Your information </h3>
            <h3 v-else-if="locale === 'de'">Ihre Informationen</h3>
            <h3 v-else>Vaši podatki</h3>
            <hr style="margin-top: 10px; margin-bottom: 20px">
            <v-row>
              <v-col>
                <v-text-field
                    v-model="buyer.name"
                    outlined
                    :label="getTranslationFor('name')"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="buyer.surname"
                    outlined
                    :label="getTranslationFor('surname')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                    v-model="buyer.email"
                    outlined
                    label="E-mail"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                    v-model="buyer.phone"
                    outlined
                    :label="getTranslationFor('phone')"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-text-field
                v-model="buyer.address"
                outlined
                :label="getTranslationFor('address')"
            >
            </v-text-field>
            <v-row>
              <v-col cols="12" sm="8" md="4">
                <v-text-field
                    v-model="buyer.zip"
                    outlined
                    :label="getTranslationFor('zip')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                    v-model="buyer.post"
                    outlined
                    :label="getTranslationFor('post')"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-text-field
                v-model="buyer.vat"
                outlined
                :label="getTranslationFor('vat')"
            >
            </v-text-field>
            <v-textarea
                v-model="buyer.notes"
                outlined
                :label="getTranslationFor('notes')"
                rows="5"
            >
            </v-textarea>

            <h4 v-if="locale === 'en'" style="margin-bottom: 0"></h4>
            <h4 v-else-if="locale === 'de'" style="margin-bottom: 0"></h4>
            <h4 v-else style="margin-bottom: 0">Način plačila:</h4>

            <v-radio-group v-model="buyer.payment">
              <v-radio
                  :label="getTranslationFor('invoice')"
                  value="INVOICE"
              ></v-radio>
              <v-radio
                  :label="getTranslationFor('at delivery')"
                  value="AT_DELIVERY"
                  :disabled="cartContainsIllegalItem()"
              ></v-radio>
            </v-radio-group>

            <v-checkbox
                v-model="buyer.agrees"
                :label="getTranslationFor('agree')"
            ></v-checkbox>

            <v-btn
              style="width: 100%"
              color="primary"
              @click="order()"
            >
              {{ getTranslationFor('submit') }}
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <div v-if="loading" class="text-center" style="padding-top: 100px; padding-bottom: 80px">
      <v-progress-circular
          :size="80"
          :width="5"
          color="#9FBB38"
          indeterminate
      ></v-progress-circular>
    </div>

    <v-dialog v-model="errorDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 mb-6" style="background-color: #a50000">
          <span style="color: white">Napaka!</span>
        </v-card-title>

        <v-card-text>
          Prepričajte se, da ste izpolnili vse podatke in se strinjali z pogoji poslovanja ter da skupna vrednost nakupa presega 9 EUR!<br><br>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              small
              @click="errorDialog = false"
          >
            Zapri
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 mb-6" style="background-color: #00a323">
          <span style="color: white">Hvala!</span>
        </v-card-title>

        <v-card-text>
          Naročilo ste uspešno oddali!<br><br>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              small
              @click="$router.push('/home')"
          >
            Zapri
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-expand-transition>
      <v-snackbar
          v-model="snackbar"
          :timeout="4000"
          :height="80"
          :right="true"
          :color="snackbarColor"
          :elevation="24"
      >
        {{ displayMessage }}

      </v-snackbar>
    </v-expand-transition>
  </div>
</template>

<script>
import EndpointsService from "@/services/endpoints.service"
import CartService from "@/services/cart.service";
import PropertiesService from "@/services/properties.service";

export default {
  name: "cart",
  title: "ZAVESE - Košarica",
  data() {
    return {
      localCart: [],
      loading: false,

      properties: {},

      buyer: {},
      errorDialog: false,
      successDialog: false,

      snackbar: false,
      snackbarColor: "#FFB300",
      displayMessage: "",
      locale: "",
    };
  },
  computed: {
    itemImagesUrl() {
      return EndpointsService.getApiV1Endpoint() + 'items/image/';
    }
  },
  mounted(){
    this.getLocalCart();
    if(localStorage.getItem("zavese-locale") === null){
      localStorage.setItem("zavese-locale", "si")
    }
    this.locale = localStorage.getItem("zavese-locale")
    this.getProperties();
  },
  methods: {

    getProperties(){
      PropertiesService.getAll().then(
          response => {
            if(response.data !== "")
              this.properties = response.data;
            else
              this.properties = {shipping: "0.0", firstPageTextSI: "", firstPageTextDE: "", firstPageTextEN: ""}
          },
          error => {
            console.log(error)
            this.displayMessage = error.response.data.message
            this.snackbarColor = "#F44336"
            this.snackbar = true
          }
      );
    },

    getTranslationFor(term){
      if(term === "name"){
        if(this.locale === "en") return "Name"
        else if(this.locale === "de") return "Name"
        else return "Ime"
      }
      if(term === "surname"){
        if(this.locale === "en") return "Surname"
        else if(this.locale === "de") return "Nachname"
        else return "Priimek"
      }
      if(term === "address"){
        if(this.locale === "en") return "Address"
        else if(this.locale === "de") return "die Anschrift"
        else return "Naslov"
      }
      if(term === "phone"){
        if(this.locale === "en") return "Phone number"
        else if(this.locale === "de") return "Telefonnummer"
        else return "Telefonska št."
      }
      if(term === "zip"){
        if(this.locale === "en") return "ZIP"
        else if(this.locale === "de") return "ZIP"
        else return "Poštna št."
      }
      if(term === "post"){
        if(this.locale === "en") return "Post"
        else if(this.locale === "de") return "Post"
        else return "Pošta"
      }
      if(term === "notes"){
        if(this.locale === "en") return "Notes"
        else if(this.locale === "de") return "Anmerkungen"
        else return "Opombe"
      }
      if(term === "invoice"){
        if(this.locale === "en") return "Invoice"
        else if(this.locale === "de") return "Rechnung"
        else return "Predračun"
      }
      if(term === "vat"){
        if(this.locale === "en") return "VAT number [optional]"
        else if(this.locale === "de") return "Umsatzsteuer-Identifikationsnummer [optional]"
        else return "Davčna št. [opcijsko]"
      }
      if(term === "at delivery"){
        if(this.cartContainsIllegalItem()){
          if(this.locale === "en") return "At delivery (Not available)"
          else if(this.locale === "de") return "Bei Lieferung (Nicht verfügbar)"
          else return "Pri prevzemu (Ni na voljo)"
        }
        else{
          if(this.locale === "en") return "At delivery"
          else if(this.locale === "de") return "Bei Lieferung"
          else return "Pri prevzemu"
        }

      }
      if(term === "agree"){
        if(this.locale === "en") return "I agree to the terms of business"
        else if(this.locale === "de") return "Ich stimme den Geschäftsbedingungen zu"
        else return "Strinjam se s pogoji poslovanja"
      }
      if(term === "submit"){
        if(this.locale === "en") return "Place order"
        else if(this.locale === "de") return "Bestellung aufgeben"
        else return "Oddaj naročilo"
      }
      if(term === "shipping"){
        if(this.locale === "en") return "Shipping"
        else if(this.locale === "de") return "Versandkosten"
        else return "Poštnina"
      }

    },

    getLocalCart(){
      this.localCart = CartService.getCart()
    },

    removeItemFromLocalCart(item){
      CartService.removeFromCart(item)
      this.getLocalCart()
    },

    getUnit(type){
      if(type === "BLINDS")
        return "€/m²"
      else if (type === "FABRIC")
        return "€/m"
      else
        return "€"
    },

    formatPrice(number, type, quantity, width, height){
      if(type === "BLINDS"){
        let area = width * height
        if(area <= 1000000)
          return number.toFixed(2)
        else{
          let multiplier = area / 1000000;
          let price = number * multiplier
          return price.toFixed(2)
        }
      }
      else {
        let price = number * quantity
        return price.toFixed(2)
      }
    },

    applyDiscount(price, discount, type, quantity, width, height){
      return this.formatPrice(parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0)), type, quantity, width, height)
    },

    applyDiscountWithoutFormatting(price, discount){
      return parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0))
    },

    getVAT(price, discount, type, quantity, width, height){
      let value = parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0));
      let netValue = value / 1.22
      let vat = value - netValue;
      return this.formatPrice(vat, type, quantity, width, height)
    },

    getNET(price, discount, type, quantity, width, height){
      let value = parseFloat(price) * (1.0 - (parseFloat(discount) / 100.0));
      return this.formatPrice(value / 1.22, type, quantity, width, height)
    },

    getDetailsText(item){
      if(item.option.description !== ""){
        if(item.item.type === "BLINDS"){
          if(this.locale === "en") return "Details: " + item.option.description + " (width: " + item.width + "mm, height: " + item.height + "mm, side: " + (item.side === 'left' ? 'L' : 'R') + ")"
          else if(this.locale === "de") return "Einzelheiten: " + item.option.description + " (Breite: " + item.width + "mm, Höhe: " + item.height + "mm, Seite: " + (item.side === 'left' ? 'L' : 'R') + ")"
          else return "Podrobnosti: " + item.option.description + " (širina: " + item.width + "mm, višina: " + item.height + "mm, stran: " + (item.side === 'left' ? 'L' : 'D') + ")"
        }
        else if (item.item.type === "FABRIC"){
          if(this.locale === "en") return "Details: " + item.option.description + " (length: " + item.quantity + "m)"
          else if(this.locale === "de") return "Einzelheiten: " + item.option.description + " (Länge: " + item.quantity + "m)"
          else return "Podrobnosti: " + item.option.description + " (dolžina: " + item.quantity + "m)"
        }
        else{
          if(this.locale === "en") return "Details: " + item.option.description + " (quantity: " + item.quantity + ")"
          else if(this.locale === "de") return "Einzelheiten: " + item.option.description + " (Anzahl: " + item.quantity + ")"
          else return "Podrobnosti: " + item.option.description + " (količina: " + item.quantity + ")"
        }
      }
      else{
        if(item.item.type === "BLINDS"){
          if(this.locale === "en") return "Details: (width: " + item.width + "mm, height: " + item.height + "mm, side: " + (item.side === 'left' ? 'L' : 'R') + ")"
          else if(this.locale === "de") return "Einzelheiten: (Breite: " + item.width + "mm, Höhe: " + item.height + "mm, Seite: " + (item.side === 'left' ? 'L' : 'R') + ")"
          else return "Podrobnosti: (širina: " + item.width + "mm, višina: " + item.height + "mm, stran: " + (item.side === 'left' ? 'L' : 'D') + ")"
        }
        else if (item.item.type === "FABRIC"){
          if(this.locale === "en") return "Details: (length: " + item.quantity + "m)"
          else if(this.locale === "de") return "Einzelheiten: (Länge: " + item.quantity + "m)"
          else return "Podrobnosti: (dolžina: " + item.quantity + "m)"
        }
        else{
          if(this.locale === "en") return "Details: (quantity: " + item.quantity + ")"
          else if(this.locale === "de") return "Einzelheiten: (Anzahl: " + item.quantity + ")"
          else return "Podrobnosti: (količina: " + item.quantity + ")"
        }
      }
    },

    order(){
      if(this.buyer.name === undefined || this.buyer.name === ""){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.surname === undefined || this.buyer.surname === ""){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.address === undefined || this.buyer.address === ""){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.zip === undefined || this.buyer.zip === ""){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.post === undefined || this.buyer.post === ""){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.email === undefined || this.buyer.email === ""){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.phone === undefined || this.buyer.phone === ""){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.payment === undefined){
        this.errorDialog = true;
        return;
      }
      if(this.buyer.agrees === undefined){
        this.errorDialog = true;
        return;
      }

      this.successDialog = true
      CartService.clearCart();
      this.buyer = {}
    },

    calculateCartTotal(){
      var shipping = this.properties.shipping
      var total = 0;
      var vat = 0;
      var net = 0;
      for (var i = 0; i<this.localCart.length; i++){
        let itemTotal = this.applyDiscount(this.localCart[i].option.price, this.localCart[i].item.discount, this.localCart[i].item.type, this.localCart[i].quantity, this.localCart[i].width, this.localCart[i].height)
        let itemNet = this.getNET(this.localCart[i].option.price, this.localCart[i].item.discount, this.localCart[i].item.type, this.localCart[i].quantity, this.localCart[i].width, this.localCart[i].height)
        total = total + parseFloat(itemTotal);
        net = net + parseFloat(itemNet);
      }
      total = total + parseFloat(shipping);
      net = net + (parseFloat(shipping) / 1.22);

      vat = total - net;
      return { total: total.toFixed(2), vat: vat.toFixed(2), net: net.toFixed(2) }
    },

    cartContainsIllegalItem(){
      for (var i = 0; i<this.localCart.length; i++){
        if(this.localCart[i].item.type === "BLINDS" || this.localCart[i].item.type === "CURTAINS")
          return true;
      }
      return false;
    }

  }
}
</script>

<style scoped>
  .main-card {
    padding: 30px;
    padding-bottom: 60px;
    margin: 15px;
    margin-top: 25px;
    background-color: #f3f3f3;
    max-width: 1800px;
  }
  .image-div{
  }
  .text-div {
  }
  .item-div{
    margin-bottom: 10px;
    background-color: #e8e8e8;
    padding: 15px;
    border-radius: 10px;
    border: solid 1px #a0a0a0;
  }

  @media only screen and (max-width: 950px){
    .main-card {
      padding: 5px;
    }
  }
</style>
